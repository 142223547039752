import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export function formatDuration(minutes: number): string {
  /* input is value in minutes */
  const durationObject = dayjs.duration(minutes * 60 * 1000);
  /* must use this value separately, because dayjs does not offer formatting of hours longer than 24 */
  const hours = durationObject.days() * 24 + durationObject.hours();

  return `${hours}:${durationObject.format('mm')} h`;
}
